import React, { useState } from 'react';
import { Layout, SEO, AllPosts, CategoryNav } from 'components';
import { graphql } from 'gatsby';
import './blog.scss';

const Blog = props => {
  const { location, data } = props;
  // destructuring query
  const { posts } = data;
  const { nodes } = posts;

  const paginationAmount = 10;

  // search bar

  const [visiblePosts, setVisiblePosts] = useState(nodes ? [...nodes].splice(0, paginationAmount) : []); // set visible posts
  const [numberOfPosts, setNumberOfPosts] = useState(paginationAmount); // set search query

  const handleLoadMore = () => {
    const newNumberOfPosts = numberOfPosts + paginationAmount;
    setNumberOfPosts(newNumberOfPosts);
    setVisiblePosts([...nodes].splice(0, newNumberOfPosts));
  };

  // render
  return (
    <Layout location={location}>
      <SEO title="Welcome to the Lemmy Blog | Lemmy" />
      <div className="blog wrapper">
        <div className="blog-title-wrapper">
          <h1 className="blog-title">Lemmy said</h1>
        </div>
        <CategoryNav />
        {/* <Categories activeCategory={activeCategory} categories={categories} setCategoryHandler={setCategoryHandler} /> */}
        <AllPosts posts={visiblePosts} onLoadMore={handleLoadMore} numberOfPosts={numberOfPosts} />
      </div>
    </Layout>
  );
};

export default Blog;

export const blogHomeQuery = graphql`
  query BlogHomeQuery {
    posts: allPrismicBlogPost(sort: { order: DESC, fields: data___date_published }) {
      nodes {
        uid
        id
        prismicId
        data {
          blog_post_title {
            html
          }
          date_published
          hero_image {
            url
            localFile {
              childImageSharp {
                fluid(quality: 80, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          hero_background_color
          post_excerpt {
            text
          }
        }
      }
    }
  }
`;
